<template>
  <models
    :showmodel="showcreate"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmcreate"
    @closemodel="closecreate"
    class="ifcreate"
  >
    <div class="maincontent">
      <el-row>
        <el-col :span="6">
          <div class="labelname"><span>收件人姓名：</span></div>
        </el-col>
        <el-col :span="18">
          <el-input
            class="namevalue"
            size="small"
            placeholder="请输入收件人姓名"
            clearable
            v-model="namevalue"
          >
          </el-input>
        </el-col>
      </el-row>
    </div>
    <div class="maincontent">
      <el-row>
        <el-col :span="6">
          <div class="labelname"><span>收件人电话：</span></div>
        </el-col>
        <el-col :span="18">
          <el-input
            class="namevalue"
            size="small"
            placeholder="请输入收件人电话"
            clearable
            v-model="namevalue"
          >
          </el-input>
        </el-col>
      </el-row>
    </div>
    <div class="maincontent">
      <el-row>
        <el-col :span="6">
          <div class="labelname"><span>收件人地址：</span></div>
        </el-col>
        <el-col :span="18">
          <el-input
            class="namevalue"
            size="small"
            placeholder="请输入收件人地址"
            clearable
            v-model="namevalue"
          >
          </el-input>
        </el-col>
      </el-row>
    </div>
  </models>
</template>
<script>
import Models from "../../../../components/Models/index.vue";
export default {
  props: {
    showcreate: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      namevalue: "",
    };
  },
  watch: {
    row: function (val) {
      this.namevalue = val.Name;
    },
  },
  components: {
    Models,
  },
  methods: {
    closecreate() {
      this.namevalue = "";
      this.$emit("closecreate");
    },
    confirmcreate() {
      if (this.title == "编辑分组") {
        let payload = {
          Id: this.row.Id,
          Name: this.namevalue,
        };
        this.$store
          .dispatch("group/editegrouplist", { ...payload })
          .then((res) => {
            if (res.RetCode == 0) {
              this.$message.success("修改成功");
              this.closecreate();
            }
          });
      } else {
        let payload = {
          Name: this.namevalue,
        };
        this.$store
          .dispatch("group/createnewgroup", { ...payload })
          .then((res) => {
            if (res.RetCode == 0) {
              this.$message.success("创建成功");
              this.closecreate();
            }
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ifcreate {
  ::v-deep .el-dialog {
    width: 450px;
    height: 360px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 180px;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    margin-top: 20px;
  }
}
</style>